/* eslint-disable max-lines */
import { memo, useCallback, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { BUILD_FLOW_STEPS, LARGE_WIDTH, MEDIUM_MAX_WIDTH, MEDIUM_WIDTH } from '@constants';
import { useIsMobile, useTranslation } from '@utils/hooks';
import { useBFContext } from '@context';
import { Flex } from '@components';
import { useCart } from '@services/shopify';
import { BUNDLE_KEY_QUERY } from '@utils/constants/cart';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import TriggerButton from './TriggerButton';
import styles from './ProductViewAllTops.module.scss';

const getTranslatedTexts = translator => {
	return {
		chooseYourTop: translator('choose-your-top'),
		chooseYourTops: translator('choose-your-tops'),
		myTopFrames: translator('my-top-frames'),
		closeUppercase: translator('close-uppercase'),
		completeYourPair: translator('complete-your-pair'),
	};
};

const ProductViewAllTops = ({ showMobile, titleOnly }: { showMobile: boolean; titleOnly?: boolean }) => {
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const isBF2AllTops = useFeatureIsOn('bf-2-all-tops');
	const isFilterOutline = useFeatureIsOn('is-filter-outline-button');
	const isAlwaysOpen = useFeatureIsOn('is-always-filter-tops');
	const isChooseTopsHover = useFeatureIsOn('is-choose-tops-hover');
	const isFilterTags = useFeatureIsOn('is-filter-tags');
	const { tops, step, handleTop, isSubscriptionActivated, skipSubscription, isQuickAdd, chooseTopsFilter, chooseTopsHandler } =
		useBFContext();
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const isMobileTablet = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });
	const isMobileLarge = useIsMobile({ maxWidth: LARGE_WIDTH });
	const hasTops = Object.keys(tops).length !== 0;
	const [modalOpen, setModalOpen] = useState(false);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const { data: cart } = useCart();
	const { query } = useRouter();
	const savedBundle = cart?.bundles && query[BUNDLE_KEY_QUERY] ? cart?.bundles[`${query[BUNDLE_KEY_QUERY]}`] : null;
	const baseFrameQuickAdd = savedBundle?.base?.frame;
	const enableFilter = isFilterOutline || isAlwaysOpen || isChooseTopsHover;

	const showProductViewActions = !isSubscriptionActivated || (isSubscriptionActivated && skipSubscription) || isV2Subscription;

	const handleModalChange = useCallback((shouldOpen: boolean) => {
		setModalOpen(shouldOpen);
	}, []);

	const topFrameStep =
		step === BUILD_FLOW_STEPS.TOP_FRAMES || (!(isBF2AllTops && !isSubscriptionActivated) && step === BUILD_FLOW_STEPS.SUBMIT);
	const titleProductView = !(enableFilter || isFilterTags) ? translations.chooseYourTop : translations.chooseYourTops;
	const triggerButton = (
		<TriggerButton
			hasTops={hasTops}
			isFilterOutline={isFilterOutline || isFilterTags}
			isAlwaysOpen={isAlwaysOpen}
			isQuickAdd={isQuickAdd}
			isMobileTablet={isMobileTablet}
			isChooseTopsHover={isChooseTopsHover}
			badge={isQuickAdd ? (baseFrameQuickAdd ? 1 : 0) + Object.keys(tops).length : Object.keys(tops).length}
		/>
	);

	return (
		<Flex
			column
			align='center'
			justify='between'
			className={styles['product-view']}
			data-use-padding={!topFrameStep}
			data-use-height-tops-step={topFrameStep}
		>
			{isMobileTablet ? (
				<MobileView
					conditionToshow={showProductViewActions && showMobile}
					triggerButton={triggerButton}
					tops={tops}
					onTrashClick={handleTop}
					isQuickAdd={isQuickAdd}
					modalOpen={modalOpen}
					handleModalChange={handleModalChange}
					titleProductView={titleProductView}
					isMobile={isMobile}
					topFrameTitle={hasTops ? translations.myTopFrames : translations.completeYourPair}
					closeLabel={translations.closeUppercase}
					filtered={Boolean(chooseTopsFilter?.filtered)}
					isMobileLarge={isMobileLarge}
					results={chooseTopsFilter?.resultNumber ?? 0}
					enableFilter={enableFilter}
					isFilterOutline={isFilterOutline}
					isAlwaysOpen={isAlwaysOpen}
					chooseTopsHandler={chooseTopsHandler}
					enableTags={isFilterTags}
				/>
			) : (
				<DesktopView
					triggerButton={triggerButton}
					showProductViewActions={showProductViewActions}
					isQuickAdd={isQuickAdd}
					modalOpen={modalOpen}
					handleModalChange={handleModalChange}
					isMobile={isMobile}
					isFilterOutline={isFilterOutline}
					isAlwaysOpen={isAlwaysOpen}
					titleProductView={titleProductView}
					tops={tops}
					topFrameTitle={hasTops ? translations.myTopFrames : translations.completeYourPair}
					closeLabel={translations.closeUppercase}
					onTrashClick={handleTop}
					resultNumber={chooseTopsFilter?.resultNumber ? chooseTopsFilter.resultNumber : 0}
					isMobileLarge={isMobileLarge}
					isChooseTopsHover={isChooseTopsHover}
					chooseTopsFilter={chooseTopsFilter}
					chooseTopsHandler={chooseTopsHandler}
					enableTags={isFilterTags}
					titleOnly={!!titleOnly}
				/>
			)}
		</Flex>
	);
};

export default memo(ProductViewAllTops);
