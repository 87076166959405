/* eslint-disable max-lines */
import { memo, ReactElement, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { Caption, Flex, Heading, TopFrameEducation, StepTracker, FilterButton, CartNavButton, FilterTags } from '@components';
import variables from '@styles/export.module.scss';
import { NormalizedVariant } from '@ts/product';
import { ChooseTopRefineType } from '@ts/contexts';
import styles from './ProductViewAllTops.module.scss';
import TopsDrawer from './TopsDrawer';
const Microcart = dynamic(() => import('@components').then(mod => mod.MicrocartQuickAdd), {
	loading: () => <CartNavButton href='/cart' />,
});
interface DesktopContentProps {
	triggerButton: ReactElement;
	showProductViewActions: boolean;
	isQuickAdd: boolean;
	modalOpen: boolean;
	handleModalChange: (shouldOpen: boolean) => void;
	isMobile: boolean;
	isFilterOutline: boolean;
	isAlwaysOpen: boolean;
	titleProductView: string;
	tops: {
		[key: string]: NormalizedVariant;
	};
	topFrameTitle: string;
	closeLabel: string;
	onTrashClick: (top: NormalizedVariant) => void;
	resultNumber: number;
	isMobileLarge: boolean;
	isChooseTopsHover: boolean;
	chooseTopsFilter: {
		callback?: (value: boolean) => void;
		value?: boolean;
		filtered?: boolean;
		resultNumber?: number;
	};
	chooseTopsHandler: ChooseTopRefineType;
	enableTags?: boolean;
	titleOnly?: boolean;
}
const DesktopView = ({
	triggerButton,
	showProductViewActions,
	isQuickAdd,
	modalOpen,
	handleModalChange,
	isMobile,
	isFilterOutline,
	isAlwaysOpen,
	titleProductView,
	tops,
	topFrameTitle,
	closeLabel,
	onTrashClick,
	resultNumber,
	isMobileLarge,
	isChooseTopsHover,
	chooseTopsFilter,
	chooseTopsHandler,
	enableTags = false,
	titleOnly = false,
}: DesktopContentProps) => {
	const { items, refine } = chooseTopsHandler ?? {};
	const Header = useCallback(
		() => (
			<Heading tag='h6' style={{ marginTop: '0' }}>
				{titleProductView}
			</Heading>
		),
		[titleProductView]
	);
	const Results = useCallback(
		() => (
			<Caption
				style={{ color: variables.gray4 }}
				data-testid='tops-counter'
				small={isMobileLarge && !titleOnly}
				medium={titleOnly}
			>
				{resultNumber} items found
			</Caption>
		),
		[resultNumber, isMobileLarge, titleOnly]
	);

	const ProductViewActions = useCallback(
		() =>
			showProductViewActions && (
				<Flex column>
					<Flex align='center' justify='center' gap={3}>
						<Header />
						<TopFrameEducation isMobile disableTitle />
					</Flex>
					{(isAlwaysOpen || isFilterOutline) && <Results />}
				</Flex>
			),
		[showProductViewActions, isAlwaysOpen, isFilterOutline, Header, Results]
	);
	const Drawer = useCallback(
		() => (
			<TopsDrawer
				triggerButton={triggerButton}
				showProductViewActions={showProductViewActions}
				isQuickAdd={isQuickAdd}
				modalOpen={modalOpen}
				handleModalChange={handleModalChange}
				isMobile={isMobile}
				tops={tops}
				topFrameTitle={topFrameTitle}
				closeLabel={closeLabel}
				onTrashClick={onTrashClick}
			/>
		),
		[
			triggerButton,
			showProductViewActions,
			isQuickAdd,
			modalOpen,
			handleModalChange,
			isMobile,
			tops,
			topFrameTitle,
			closeLabel,
			onTrashClick,
		]
	);

	const Container = useCallback(
		({ children }) => (
			<Flex justify='between' fullWidth align='center' column={isFilterOutline || isAlwaysOpen || enableTags} gap={0}>
				{children}
			</Flex>
		),
		[isFilterOutline, isAlwaysOpen, enableTags]
	);
	const ConditionalContainer = useCallback(
		({ children }) =>
			enableTags || isAlwaysOpen || isFilterOutline ? (
				<Flex fullWidth={isMobileLarge && enableTags} justify={isMobileLarge && enableTags ? 'between' : undefined}>
					{children}
				</Flex>
			) : (
				children
			),
		[enableTags, isMobileLarge, isAlwaysOpen, isFilterOutline]
	);

	if (titleOnly) {
		return (
			<Flex fullWidth justify='between' className={styles['title-only']}>
				<Flex align='center' justify='center' gap={3}>
					<Header />
					<TopFrameEducation isMobile disableTitle />
				</Flex>
				<Results />
			</Flex>
		);
	}

	if (isChooseTopsHover && !(isFilterOutline || isAlwaysOpen || enableTags)) {
		return (
			<Container>
				<ProductViewActions />
				<Flex key='step-tracker' fullWidth={!showProductViewActions}>
					<StepTracker />
				</Flex>
				<Drawer />
				{isQuickAdd && <Microcart triggerButton={triggerButton} tops={tops} onTrashClick={onTrashClick} />}
			</Container>
		);
	}

	if (!(isFilterOutline || isAlwaysOpen || enableTags)) {
		return (
			<Container>
				{showProductViewActions && (
					<Flex column>
						<Header />
						<TopFrameEducation isMobile />
					</Flex>
				)}
				<Flex key='step-tracker' fullWidth={!showProductViewActions}>
					<StepTracker />
				</Flex>
				<Drawer />
				{isQuickAdd && <Microcart triggerButton={triggerButton} tops={tops} onTrashClick={onTrashClick} />}
			</Container>
		);
	}

	return (
		<Container>
			<Flex key='step-tracker' fullWidth={!showProductViewActions}>
				<StepTracker />
			</Flex>
			<Flex align='center' justify='between' fullWidth fullHeight className={styles['isFilter']}>
				{!enableTags ? <ProductViewActions /> : !isMobileLarge && <FilterTags items={items} refine={refine} />}
				<ConditionalContainer>
					{(!isAlwaysOpen || isMobileLarge) && (
						<FilterButton
							color='blue-outline'
							isFilterOutline
							onClick={chooseTopsFilter?.callback}
							open={!!chooseTopsFilter?.value}
							filtered={!!chooseTopsFilter?.filtered}
						/>
					)}
					{!isQuickAdd ? (
						<Drawer />
					) : (
						<Microcart triggerButton={triggerButton} tops={tops} onTrashClick={onTrashClick} />
					)}
				</ConditionalContainer>
			</Flex>
			{enableTags && isMobileLarge && <FilterTags items={items} refine={refine} />}
		</Container>
	);
};
export default memo(DesktopView);
