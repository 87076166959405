import cn from 'classnames';
import { CSSProperties } from 'react';
import { Button } from '@components';
import { Filter } from '@components/icons';
import { useFilterContext } from '@context';
import { useTranslation } from '@utils/index';
import styles from './FilterButton.module.scss';
interface FilterButtonProps {
	color: 'green' | 'blue' | 'white' | 'transparent-light' | 'transparent-dark' | 'blue-outline';
	isFilterOutline?: boolean;
	onClick?: (value: boolean) => void;
	open?: boolean;
	filtered?: boolean;
	style?: CSSProperties;
	disablePadding?: boolean;
}

const getTranslatedTexts = translator => {
	return {
		filtersApplied: translator('filters-applied'),
		filterToggleLabel: (filterAction: string) => translator('filter-toggle-label', { filterAction }),
		hideWord: translator('hide-word'),
		showWord: translator('show-word'),
	};
};

const FilterButton = ({
	color,
	isFilterOutline = false,
	onClick,
	open,
	filtered,
	style,
	disablePadding = false,
}: FilterButtonProps) => {
	const { setIsOpen, isOpen, type } = useFilterContext() || {};
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isBFAllTops = type === 'bf-all-tops';
	const toggleWord = !!isOpen || !!open ? translations.hideWord : translations.showWord;
	const copy = translations.filterToggleLabel(toggleWord);
	const filteredCopy = translations.filtersApplied;

	return (
		<div
			data-filter-button={type}
			className={cn(styles.filtersButton, {
				[styles.filtersButtonBfTops]: isBFAllTops,
				[styles.filtersButtonIsFilterOutline]: isFilterOutline,
				[styles.filtersButtonDisablePadding]: disablePadding,
			})}
			style={style}
		>
			<Button
				color={color}
				data-open-filter={!!isOpen}
				onClick={() => {
					setIsOpen?.(!isOpen);
					onClick?.(!open);
				}}
				fullWidth
			>
				<Filter color='currentColor' style={{ maxWidth: 'fit-content' }} />
				<span>{filtered ? filteredCopy : copy}</span>
			</Button>
		</div>
	);
};

export default FilterButton;
