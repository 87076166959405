/* eslint-disable max-lines */
import { useCallback } from 'react';
import { Flex, TagFitler } from '@components';
import { ChooseTopRefineType } from '@ts/contexts';
import { useIsMobile } from '@utils/hooks';
import { LARGE_WIDTH, MOBILE_MAX_WIDTH } from '@constants';
import styles from './Filter.module.scss';

interface FilterTagsProps {
	items: ChooseTopRefineType['items'];
	refine: ChooseTopRefineType['refine'];
}

const FilterTags = ({ items = [], refine }: FilterTagsProps) => {
	const isMobileLarge = useIsMobile({ maxWidth: LARGE_WIDTH });
	const isMobile = useIsMobile({ maxWidth: MOBILE_MAX_WIDTH });
	const refinedItems = items.filter(i => i.isRefined).map(i => i.value);
	const removePreviousRefinedItems = useCallback(
		(hasRefinedItems: boolean) => {
			if (hasRefinedItems) refinedItems.forEach(refinedItem => refine(refinedItem));
		},
		[refinedItems, refine]
	);

	const mobileProps = {
		justify: 'center' as const,
		align: 'center' as const,
		fullWidth: true,
	};
	return (
		<Flex gap={3} className={styles.container} {...(isMobileLarge && mobileProps)}>
			{items
				.sort((a, b) => a.label.localeCompare(b.label))
				.map(item => (
					<TagFitler
						key={item.label}
						name={item.label}
						callback={() => {
							const hasRefinedItems = refinedItems.length > 0 && !refinedItems.includes(item.value);
							removePreviousRefinedItems(hasRefinedItems);
							refine(item.value);
						}}
						isActiveStyle={item.isRefined}
						medium={isMobile}
					/>
				))}
		</Flex>
	);
};

export default FilterTags;
